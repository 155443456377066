<template>
  <div class="foot-cont">
    <div class="foot-box">
      <p>陆老师:186-1273-3266</p>
      <a href="tel:18612733266" class="btn">拨打电话</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer',
  components: {},
  methods: {},
  mounted() {
  },
}
</script>
<style lang="less" scoped>
.foot-cont {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.07);
  position: fixed;
  bottom: 0px;

  .foot-box {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;

    p {
      line-height: 60px;
      font-size: 14px;
    }

    .btn {
      display: inline-block;
      margin-top: 4%;
      width: 102px;
      height: 35px;
      line-height: 35px;
      background: #0079F4;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

}
</style>
