<template>
  <div class="header-cont">
    <div class="header-box">
      <div class="header-item">
        <div class="back" @click="goBack()"><img src="../../../../assets/images/securityTrain/h5/home/back.png"></div>
        <div class="logo"><img src="../../../../assets/images/securityTrain/h5/home/logo.png"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer',
  components: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
.header-cont {
  width: 100%;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.07);
  position: fixed;
  top: 0px;
  z-index: 100;
 .header-box{
   width: 95%;
   margin: 0 auto;
 }
  .header-item {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
  }

  .back {
    overflow: hidden;
    position: relative;

    img {
      width: 10px;
      height: 15px;
    }
  }

  .logo {
    img {
      width: 72px;
      height: 21px;
    }
  }
}

</style>
